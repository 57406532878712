import React, { useState } from 'react'
import { RRule } from 'rrule'
import { uniq } from 'lodash'
import moment from 'moment-timezone'
import { Dropdown } from 'primereact/dropdown'

function CustomizeSchedules({ schedules, handleCustomizeStartTime, handleCustomizeEndTime }) {
  const availableTimes = uniq(schedules.flatMap((schedule) => {
    const rruleOptions = RRule.parseString(schedule.rruleForDose)
    const start = moment.utc(schedule.startAt).tz(rruleOptions.tzid)
    const end = moment.utc(schedule.startAt).add(1, 'days').tz(rruleOptions.tzid)
    const occurrences = new RRule(rruleOptions).between(start.toDate(), end.toDate())

    //
    // The getUTC*() values returned from the dates generated by RRule are actually
    // *local* time values, not UTC, or the timezone specified in the rule. The timezone
    // of the schedule is different than local time, so we have to convert the local time
    // generated by RRule to the schedule's timezone in order to get the right display
    // values. When we pass the faux/local UTC string to moment for parsing, we need to
    // remove the timezone from the end of the string because we want it to parse as local
    // time not UTC.
    //
    // Read more here: https://github.com/jkbrzt/rrule?tab=readme-ov-file#important-use-utc-dates
    //
    return occurrences.map((occurrence) => moment(occurrence.toUTCString().replace('GMT', '')).tz(rruleOptions.tzid).format('h:mm A'))
  }))

  const [customizedStartTime, setCustomizedStartTime] = useState()
  const [customizedEndTime, setCustomizedEndTime] = useState()

  const updateCustomizedStartTime = ({ value }) => {
    setCustomizedStartTime(value)
    handleCustomizeStartTime(value)
  }

  const updateCustomizedEndTime = ({ value }) => {
    setCustomizedEndTime(value)
    handleCustomizeEndTime(value)
  }

  if (availableTimes.length < 2) {
    return null
  }

  return (
    <div>
      <div className="flex align-items-center mt-3">
        Start the schedule with the
        <Dropdown
          showClear
          className="mx-2"
          value={customizedStartTime}
          options={availableTimes}
          onChange={updateCustomizedStartTime}
        />
        administration time
      </div>
      <div className="flex align-items-center mt-3">
        End the schedule with the
        <Dropdown
          showClear
          className="mx-2"
          value={customizedEndTime}
          options={availableTimes}
          onChange={updateCustomizedEndTime}
        />
        administration time
      </div>
    </div>
  )
}

export default CustomizeSchedules
